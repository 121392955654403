// Admin router

const ADMIN_PATH = "/admin";
const ADMIN_PATH_LOGIN = "/admin-login";

// Admin Master
// 1. Status Color
const ADMIN_M_STATUR_COLOR_PATH = "m-status-color";

// Admin Project
const ADMIN_PROJECT_PATH = "project";
const ADMIN_PROJECT_NEW_PATH = "project-new";
const ADMIN_PROJECT_EDIT_PATH = "project-edit/:id";

// Admin Building
const ADMIN_BUILDING_PATH = "building";

// Admin Apartment
const ADMIN_APARTMENT_PATH = "apartment";

// Admin Post
const ADMIN_POST_PATH = "post";
const ADMIN_POST_NEW_PATH = "post-new";
const ADMIN_POST_EDIT_PATH = "post-edit/:id";

// Admin Post-category
const ADMIN_POST_CATEGORY_PATH = "post-category";

// Admin Sales-Policy
const ADMIN_SALES_POLICY_PATH = "sales-policy";

// Admin Product
const ADMIN_PRODUCT_PATH = "product";
const ADMIN_PRODUCT_NEW_PATH = "product/new";
const ADMIN_PRODUCT_EDIT_PATH = "product/edit/:id";
const ADMIN_CATEGORY_PATH = "category";
const ADMIN_CATEGORY_NEW_PATH = "category/new";
const ADMIN_CATEGORY_EDIT_PATH = "category/edit/:id";
const ADMIN_ATTRIBUE_PATH = "attributes";
const ADMIN_ATTRIBUE_NEW_PATH = "attributes/new";
const ADMIN_ATTRIBUE_EDIT_PATH = "attributes/edit/:id";
const ADMIN_ATTRIBUE_ADD_ITEM_PATH = "attributes/:id/items";
const ADMIN_RENT_POST = "rent-post";

// Admin Order
const ADMIN_ORDER_PATH = "order";

// Admin User
const ADMIN_USER_PATH = "user";
const ADMIN_USER_NEW_PATH = "user-new";
const ADMIN_USER_EDIT_PATH = "user-edit/:id";

// Admin Setting
const ADMIN_SETTING_PATH = "setting";
const ADMIN_SETTING_NEW_PATH = "setting-new";
const ADMIN_SETTING_EDIT_URL = "setting-edit";
const ADMIN_SETTING_EDIT_PATH = ADMIN_SETTING_EDIT_URL + "/:id";

const ADMIN_ALBUM_PATH = "album";
const ADMIN_ALBUM_NEW_PATH = "album/new";
const ADMIN_ALBUM_EDIT_PATH = "album/edit/:id";

// Admin Employees (Quản lý nhân viên)
const ADMIN_EMPLOYEES_PATH = "employees";
const ADMIN_EMPLOYEES_NEW_PATH = "employees-new";
const ADMIN_EMPLOYEES_EDIT_PATH = "employees-edit/:id";

// Phân quyền
const ADMIN_USER_ROLE_PATH = "permission";

export {
  ADMIN_PATH,
  ADMIN_PATH_LOGIN,
  ADMIN_PROJECT_PATH,
  ADMIN_PROJECT_NEW_PATH,
  ADMIN_PROJECT_EDIT_PATH,
  ADMIN_BUILDING_PATH,
  ADMIN_APARTMENT_PATH,
  ADMIN_POST_PATH,
  ADMIN_POST_NEW_PATH,
  ADMIN_POST_EDIT_PATH,
  ADMIN_POST_CATEGORY_PATH,
  ADMIN_USER_EDIT_PATH,
  ADMIN_CATEGORY_PATH,
  ADMIN_CATEGORY_NEW_PATH,
  ADMIN_CATEGORY_EDIT_PATH,
  ADMIN_ATTRIBUE_PATH,
  ADMIN_ATTRIBUE_NEW_PATH,
  ADMIN_ATTRIBUE_EDIT_PATH,
  ADMIN_ATTRIBUE_ADD_ITEM_PATH,
  ADMIN_USER_PATH,
  ADMIN_SETTING_PATH,
  ADMIN_SETTING_NEW_PATH,
  ADMIN_SETTING_EDIT_PATH,
  ADMIN_SETTING_EDIT_URL,
  ADMIN_USER_NEW_PATH,
  ADMIN_PRODUCT_PATH,
  ADMIN_PRODUCT_NEW_PATH,
  ADMIN_PRODUCT_EDIT_PATH,
  ADMIN_ORDER_PATH,
  ADMIN_ALBUM_PATH,
  ADMIN_ALBUM_NEW_PATH,
  ADMIN_ALBUM_EDIT_PATH,
  ADMIN_M_STATUR_COLOR_PATH,
  ADMIN_SALES_POLICY_PATH,
  ADMIN_RENT_POST,
  ADMIN_EMPLOYEES_PATH,
  ADMIN_EMPLOYEES_NEW_PATH,
  ADMIN_EMPLOYEES_EDIT_PATH,
  ADMIN_USER_ROLE_PATH,
};
