import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AdminContext } from "context/AdminContext";
import { isTokenValid } from "common/Auth";
import {
  ADMIN_PATH_LOGIN,
  ADMIN_PATH,
  ADMIN_SETTING_PATH,
  ADMIN_SETTING_NEW_PATH,
  ADMIN_SETTING_EDIT_PATH,
  ADMIN_USER_PATH,
  ADMIN_USER_NEW_PATH,
  ADMIN_USER_EDIT_PATH,
  ADMIN_PROJECT_PATH,
  ADMIN_BUILDING_PATH,
  ADMIN_APARTMENT_PATH,
  ADMIN_POST_PATH,
  ADMIN_POST_NEW_PATH,
  ADMIN_POST_EDIT_PATH,
  ADMIN_POST_CATEGORY_PATH,
  ADMIN_M_STATUR_COLOR_PATH,
  ADMIN_SALES_POLICY_PATH,
  ADMIN_RENT_POST,
  ADMIN_EMPLOYEES_PATH,
  ADMIN_EMPLOYEES_NEW_PATH,
  ADMIN_EMPLOYEES_EDIT_PATH,
  ADMIN_USER_ROLE_PATH,
} from "constant/authPath";
import AuthAdmin from "page/AuthAdmin";
import AdminLogin from "page/AuthAdmin/AdminLogin";
import Admin from "page/Admin";
import Dashboard from "page/Admin/Dashboard";
import AdminProviderWrapper from "page/AdminProviderWrapper";
import AdminSettings from "page/Admin/AdminSettings";
import AdminSettingNew from "page/Admin/AdminSettingNew";
import AdminUser from "page/Admin/AdminUser";
import AdminUserNew from "page/Admin/AdminUserNew";
import AdminProject from "page/Admin/AdminProject";
import AdminBuilding from "page/Admin/AdminBuilding";
import AdminApartment from "page/Admin/AdminApartment";
import AdminPost from "page/Admin/AdminPost";
import AdminPostNew from "page/Admin/AdminPostNew";
import AdminCategory from "page/Admin/AdminCategory";
import AdminMStatusColor from "page/Admin/AdminMStatusColor";
import AdminSalesPolicy from "page/Admin/AdminSalesPolicy";
import AdminRentPost from "page/Admin/AdminRentPost/AdminRentPost";
import AdminEmployees from "page/Admin/AdminEmployees";
import AdminEmployeesNew from "page/Admin/AdminEmployeesNew/AdminEmployeesNew";
import AdminGroupPermission from "page/Admin/AdminGroupPermission";

const PrivateRoute = ({ element: Element, ...rest }) => {
  const { admin } = useContext(AdminContext);
  const adminToken = localStorage.getItem("adminToken");

  // Kiểm tra token còn hạn và quyền truy cập
  const isAuthenticated = admin && isTokenValid(adminToken);
  return isAuthenticated ? (
    <Element {...rest} />
  ) : (
    <Navigate to={ADMIN_PATH_LOGIN} />
  );
};

const adminRouter = [
  {
    element: <AdminProviderWrapper />,
    path: ADMIN_PATH,
    children: [
      {
        element: <PrivateRoute element={Admin} />,
        path: "",
        children: [
          {
            element: <PrivateRoute element={Dashboard} />,
            path: "",
          },
          {
            element: <PrivateRoute element={AdminProject} />,
            path: ADMIN_PROJECT_PATH,
          },
          {
            element: <PrivateRoute element={AdminBuilding} />,
            path: ADMIN_BUILDING_PATH,
          },
          {
            element: <PrivateRoute element={AdminApartment} />,
            path: ADMIN_APARTMENT_PATH,
          },
          {
            element: <PrivateRoute element={AdminSettings} />,
            path: ADMIN_SETTING_PATH,
          },
          {
            element: <PrivateRoute element={AdminSettingNew} />,
            path: ADMIN_SETTING_NEW_PATH,
          },
          {
            element: <PrivateRoute element={AdminSettingNew} />,
            path: ADMIN_SETTING_EDIT_PATH,
          },
          {
            element: <PrivateRoute element={AdminUser} />,
            path: ADMIN_USER_PATH,
          },
          {
            element: <PrivateRoute element={AdminUserNew} />,
            path: ADMIN_USER_NEW_PATH,
          },
          {
            element: <PrivateRoute element={AdminUserNew} />,
            path: ADMIN_USER_EDIT_PATH,
          },
          {
            element: <PrivateRoute element={AdminPost} />,
            path: ADMIN_POST_PATH,
          },
          {
            element: <PrivateRoute element={AdminPostNew} />,
            path: ADMIN_POST_NEW_PATH,
          },
          {
            element: <PrivateRoute element={AdminPostNew} />,
            path: ADMIN_POST_EDIT_PATH,
          },
          {
            element: <PrivateRoute element={AdminCategory} />,
            path: ADMIN_POST_CATEGORY_PATH,
          },
          {
            element: <PrivateRoute element={AdminMStatusColor} />,
            path: ADMIN_M_STATUR_COLOR_PATH,
          },
          {
            element: <PrivateRoute element={AdminSalesPolicy} />,
            path: ADMIN_SALES_POLICY_PATH,
          },
          {
            element: <PrivateRoute element={AdminRentPost} />,
            path: ADMIN_RENT_POST,
          },
          {
            element: <PrivateRoute element={AdminEmployees} />,
            path: ADMIN_EMPLOYEES_PATH,
          },
          {
            element: <PrivateRoute element={AdminEmployeesNew} />,
            path: ADMIN_EMPLOYEES_NEW_PATH,
          },
          {
            element: <PrivateRoute element={AdminEmployeesNew} />,
            path: ADMIN_EMPLOYEES_EDIT_PATH,
          },
          {
            element: <PrivateRoute element={AdminGroupPermission} />,
            path: ADMIN_USER_ROLE_PATH,
          },
        ],
      },
    ],
  },
  {
    element: <AdminProviderWrapper />,
    path: ADMIN_PATH_LOGIN,
    children: [
      {
        element: <AuthAdmin />,
        path: "",
        children: [
          {
            element: <AdminLogin />,
            path: "",
          },
        ],
      },
    ],
  },
];

export default adminRouter;
