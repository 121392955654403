import axiosAdmin from "api/config/axiosAdmin";

/**
 * Thêm mới nhân viên
 *
 * @author DungPT
 * @param {object} userData
 * @param {object} employeesData
 * @param {object} bankData
 * @returns
 */
const addNewEmployees = (userData, employeesData, bankData) => {
  const url = "admin/employees";
  return axiosAdmin.post(url, {
    userData,
    employeesData,
    bankData,
  });
};

/**
 * Update thông tin nhân viên
 *
 * @author DungPT
 * @param {id} id
 * @param {Object} userData
 * @param {Object} employeesData
 * @param {Object} bankData
 * @returns
 */
const updateEmployees = (id, userData, employeesData, bankData) => {
  const url = `admin/employees/${id}`;
  return axiosAdmin.put(url, {
    userData,
    employeesData,
    bankData,
  });
};

/**
 * Get max ID nhân viên hiện tại
 *
 * @returns ID nhân viên
 */
const getNextImployeesId = () => {
  const url = "admin/employees-next-id";
  return axiosAdmin.get(url);
};

/**
 * Danh sách nhân viên (có phân trang, và điều kiện search)
 *
 * @author DungPT
 * @param {int} perPage
 * @param {int} page
 * @param {object} dataSearch Điều kiện search
 * @returns
 */
const getListEmployees = (perPage, page, status, startDate, sort) => {
  const url = "admin/employees";
  return axiosAdmin.get(url, {
    params: {
      perPage,
      page,
      status,
      startDate,
      sort,
    },
  });
};

const getEmployeeDetails = (id) => {
  const url = `/admin/employees/${id}`;
  return axiosAdmin.get(url);
};

export {
  addNewEmployees,
  getNextImployeesId,
  getListEmployees,
  getEmployeeDetails,
  updateEmployees,
};
