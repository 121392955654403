import React, { useState, useEffect } from "react";
import {
  Table,
  Checkbox,
  Button,
  List,
  message,
  Spin,
  Modal,
  Input,
} from "antd";
import classNames from "classnames/bind";
import styles from "page/Admin/AdminGroupPermission/AdminGroupPermission.module.scss";
import {
  getListAllGroup,
  getListAllRoleResources,
  getGroupPermissionByGroupId,
  saveGroupPermistion,
} from "api/admin/permission.api";
import GroupModal from "components/Modal/GroupModal";
import UserGroupModal from "components/Modal/UserGroupModal";

const cx = classNames.bind(styles);

const AdminGroupPermission = () => {
  const [groups, setGroups] = useState([]);
  const [roles, setRoles] = useState([]);
  const [resources, setResources] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [selectedGroupName, setSelectedGroupName] = useState(null);
  const [permissions, setPermissions] = useState({});
  const [loading, setLoading] = useState(false);

  // State cho modal
  const [isAddGroupModalVisible, setIsAddGroupModalVisible] = useState(false);
  const [isEditGroupModalVisible, setIsEditGroupModalVisible] = useState(false);
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [newGroupName, setNewGroupName] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    Promise.all([getListAllGroup(), getListAllRoleResources()])
      .then(([groupResponse, roleResourceResponse]) => {
        if (groupResponse) setGroups(groupResponse);
        if (roleResourceResponse) {
          setRoles(roleResourceResponse.roles || []);
          setResources(roleResourceResponse.resources || []);
        }
      })
      .catch(() => message.error("Failed to fetch initial data"))
      .finally(() => setLoading(false));
  };

  // Xử lý khi chọn nhóm
  const handleGroupSelect = (group) => {
    setSelectedGroupId(group.id);
    setSelectedGroupName(group.name);
    setLoading(true);
    getGroupPermissionByGroupId(group.id)
      .then((response) => {
        console.log("Check data phân quyền: ", response);

        // Khởi tạo chính xác trạng thái của permissions với key mới
        setPermissions(
          response.reduce((acc, perm) => {
            const { roleId, resourceId, permission } = perm; // Dùng key mới từ API
            if (!acc[roleId]) acc[roleId] = {};
            acc[roleId][resourceId] = permission; // 'allow' hoặc 'deny'
            return acc;
          }, {})
        );
      })
      .catch(() => {
        message.error("Failed to fetch group permissions");
      })
      .finally(() => setLoading(false));
  };

  // Thay đổi quyền
  const handlePermissionChange = (roleId, resourceId) => {
    setPermissions((prev) => ({
      ...prev,
      [roleId]: {
        ...prev[roleId], // Giữ nguyên các resource khác trong roleId
        [resourceId]: prev[roleId]?.[resourceId] === "allow" ? "deny" : "allow", // Chỉ thay đổi trạng thái resource cụ thể
      },
    }));
  };

  const handleCheckAll = (roleId) => {
    const updatedPermissions = { ...permissions };
    updatedPermissions[roleId] = {};

    resources.forEach((resource) => {
      updatedPermissions[roleId][resource.resource_id] = "allow";
    });

    setPermissions(updatedPermissions);
  };

  const handleUncheckAll = (roleId) => {
    const updatedPermissions = { ...permissions };
    updatedPermissions[roleId] = {};

    resources.forEach((resource) => {
      updatedPermissions[roleId][resource.resource_id] = "deny";
    });

    setPermissions(updatedPermissions);
  };

  // Chuyển đổi permissions từ dạng object sang array
  const transformPermissions = () => {
    const transformed = [];
    for (const [roleId, resources] of Object.entries(permissions)) {
      for (const [resourceId, permission] of Object.entries(resources)) {
        transformed.push({
          roleId: parseInt(roleId, 10), // Sử dụng key mới roleId
          resourceId: parseInt(resourceId, 10), // Sử dụng key mới resourceId
          permission, // Giữ nguyên permission
        });
      }
    }
    return transformed;
  };

  const handleCheckAllChange = (roleId, checked) => {
    setPermissions((prev) => {
      const updatedPermissions = { ...prev };
      updatedPermissions[roleId] = {};
      resources.forEach((resource) => {
        updatedPermissions[roleId][resource.resource_id] = checked
          ? "allow"
          : "deny";
      });
      return updatedPermissions;
    });
  };

  const isCheckAll = (roleId) => {
    return resources.every(
      (resource) => permissions[roleId]?.[resource.resource_id] === "allow"
    );
  };

  // Lưu quyền của nhóm
  const handleSave = () => {
    setLoading(true);
    const transformedPermissions = transformPermissions();
    saveGroupPermistion(selectedGroupId, transformedPermissions)
      .then(() => {
        message.success("Cập nhật phân quyền thành công");
      })
      .catch(() => {
        message.error("Cập nhật quyền thất bại");
      })
      .finally(() => setLoading(false));
  };

  // Tạo cột bảng cho vai trò
  const columns = roles.map((role) => ({
    title: (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <span>{role.role_name}</span>
        <Checkbox
          onChange={(e) => handleCheckAllChange(role.role_id, e.target.checked)}
          checked={isCheckAll(role.role_id)}
          indeterminate={
            !isCheckAll(role.role_id) &&
            Object.values(permissions[role.role_id] || {}).some(
              (value) => value === "allow"
            )
          }
        />
      </div>
    ),
    dataIndex: role.role_id,
    key: role.role_id,
    align: "center",
    render: (_, record) => (
      <Checkbox
        checked={permissions[role.role_id]?.[record.resource_id] === "allow"}
        onChange={() =>
          handlePermissionChange(role.role_id, record.resource_id)
        }
      />
    ),
  }));

  // Dữ liệu tài nguyên
  const resourceData = resources.map((resource) => ({
    key: resource.resource_id,
    resource_id: resource.resource_id,
    resource_name: resource.resource_name,
  }));

  const openAddGroupModal = () => {
    setNewGroupName("");
    setIsAddGroupModalVisible(true);
  };

  const openEditGroupModal = (group) => {
    setCurrentGroup(group);
    setNewGroupName(group.name);
    setIsEditGroupModalVisible(true);
  };

  const openUserModal = (group) => {
    setCurrentGroup(group);
    setIsUserModalVisible(true);
  };

  const handleAddGroup = () => {
    if (newGroupName.trim() === "") {
      message.error("Group name cannot be empty");
      return;
    }
    // API call to add group (mockup)
    const newGroup = { id: Date.now(), name: newGroupName };
    setGroups([...groups, newGroup]);
    message.success("Group added successfully");
    setIsAddGroupModalVisible(false);
  };

  const handleEditGroup = () => {
    if (newGroupName.trim() === "") {
      message.error("Group name cannot be empty");
      return;
    }
    setGroups(
      groups.map((group) =>
        group.id === currentGroup.id ? { ...group, name: newGroupName } : group
      )
    );
    message.success("Group updated successfully");
    setIsEditGroupModalVisible(false);
  };

  const groupListActions = (group) => (
    <div style={{ display: "flex", gap: "10px" }}>
      <Button size="small" onClick={() => openEditGroupModal(group)}>
        Chỉnh sửa
      </Button>
      <Button size="small" onClick={() => openUserModal(group)}>
        Tài khoản
      </Button>
    </div>
  );

  return (
    <Spin spinning={loading} tip="Loading...">
      <div style={{ padding: "20px" }}>
        <h1>Phân quyền hệ thống</h1>
        <div style={{ display: "flex", gap: "20px" }}>
          {/* Danh sách nhóm người dùng */}
          <div style={{ flex: 1 }}>
            <h3>Nhóm quyền</h3>
            <List
              bordered
              dataSource={groups}
              renderItem={(group) => (
                <List.Item
                  className={cx({
                    "user-active": selectedGroupId === group.id,
                    "user-item": selectedGroupId !== group.id,
                  })}
                  onClick={() => handleGroupSelect(group)}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <span onClick={() => handleGroupSelect(group)}>
                      {group.name}
                    </span>
                    {groupListActions(group)}
                  </div>
                </List.Item>
              )}
            />
            <Button type="dashed" onClick={openAddGroupModal}>
              + Thêm nhóm quyền
            </Button>
          </div>

          {/* Bảng quyền */}
          <div style={{ flex: 2 }}>
            <h3>Phân quyền cho nhóm: {selectedGroupName}</h3>
            {selectedGroupId && (
              <>
                <Table
                  columns={[
                    {
                      title: "Chức năng",
                      dataIndex: "resource_name",
                      key: "resource_name",
                    },
                    ...columns,
                  ]}
                  dataSource={resourceData}
                  pagination={false}
                  rowKey="resource_id"
                />
                <Button
                  type="primary"
                  onClick={handleSave}
                  style={{ marginTop: "20px" }}
                >
                  Lưu
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      <GroupModal
        title="Tạo mới nhóm quyền"
        visible={isAddGroupModalVisible}
        onSuccess={() => {
          setIsAddGroupModalVisible(false);
          fetchData(); // Reload groups after successful addition
        }}
        onCancel={() => setIsAddGroupModalVisible(false)}
      />

      {/* Modal Edit Group */}
      <GroupModal
        title="Chỉnh sửa nhóm quyền"
        visible={isEditGroupModalVisible}
        onSuccess={() => {
          setIsEditGroupModalVisible(false);
          fetchData(); // Reload groups after successful edit
        }}
        onCancel={() => setIsEditGroupModalVisible(false)}
        initialGroupId={currentGroup?.id || null}
        initialGroupName={currentGroup?.name || ""}
        initialDescription={currentGroup?.description || ""}
      />

      {/* Modal User List */}
      <UserGroupModal
        visible={isUserModalVisible}
        onCancel={() => setIsUserModalVisible(false)}
        groupId={currentGroup?.id || ""}
        groupName={currentGroup?.name || ""}
      />
    </Spin>
  );
};

export default AdminGroupPermission;
