import React, { useState, useEffect } from "react";
import {
  Button,
  Layout,
  Row,
  Col,
  Table,
  Pagination,
  message,
  Select,
  DatePicker,
  Spin,
} from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import classNames from "classnames/bind";
import { getListEmployees } from "api/admin/employees.api";
import EmployeeDetailModal from "components/Modal/EmployeeDetailModal";

import styles from "./AdminEmployees.module.scss";

// config
import { GENDER } from "constant/common";
import { STATUS } from "constant/employees";

const { Content } = Layout;
const { Option } = Select;

const cx = classNames.bind(styles);

const AdminEmployees = () => {
  const [employees, setEmployees] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [total, setTotal] = useState(0);
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [loading, setLoading] = useState(false); // Thêm state loading
  const [sortField, setSortField] = useState(""); // Thêm trạng thái cột sắp xếp
  const [sortOrder, setSortOrder] = useState(""); // Thêm trạng thái hướng sắp xếp
  const [isModalOpen, setIsModalOpen] = useState(false); // Trạng thái Modal
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null); // ID nhân viên được chọn

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Bật trạng thái loading
      try {
        const response = await getListEmployees(
          perPage,
          page,
          status,
          startDate ? startDate.format("YYYY-MM-DD") : undefined,
          sortField ? `${sortField},${sortOrder}` : undefined
        );
        if (response && response.data) {
          setEmployees(response.data || []); // Đảm bảo dữ liệu luôn là một mảng
          setTotal(response.total); // Tổng số bản ghi
        }
      } catch (error) {
        message.error("Không thể tải dữ liệu: " + error.message);
        setEmployees([]); // Thiết lập mảng rỗng khi gặp lỗi
        setTotal(0);
      } finally {
        setLoading(false); // Tắt trạng thái loading
      }
    };

    fetchData();
  }, [perPage, page, status, startDate, sortField, sortOrder]);

  const handleSort = (field) => {
    // Xử lý đổi hướng sắp xếp
    const newSortOrder =
      sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(newSortOrder);
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleEmployeeClick = (employeeId) => {
    setSelectedEmployeeId(employeeId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedEmployeeId(null);
  };

  const columns = [
    {
      title: (
        <a onClick={() => handleSort("employee_id")}>
          Mã nhân viên{" "}
          {sortField === "employee_id" && (sortOrder === "asc" ? "↑" : "↓")}
        </a>
      ),
      dataIndex: "employee_id",
      key: "employee_id",
      render: (value, record) => (
        <a
          onClick={() => handleEmployeeClick(record.id)}
          style={{ cursor: "pointer", color: "#1890ff" }}
        >
          {value}
        </a>
      ),
    },
    {
      title: "Họ và tên",
      key: "ho_va_ten",
      render: (text, record) => `${record.last_name} ${record.first_name}`,
    },
    {
      title: "Ngày sinh nhật",
      dataIndex: "birthday_date",
      key: "birthday_date",
      render: (value) => {
        const isBirthdayMonth = value
          ? moment(value).month() === moment().month()
          : false;
        return (
          <>
            {value ? moment(value).format("DD/MM/YYYY") : "Không xác định"}
            {isBirthdayMonth && <span style={{ marginLeft: 8 }}>🎂</span>}
          </>
        );
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (value) => {
        const status = STATUS.find((s) => s.value === value);
        return status ? status.label : "Không xác định";
      },
    },
    { title: "Chức vụ", dataIndex: "chuc_vu", key: "chuc_vu" },
    {
      title: (
        <a onClick={() => handleSort("bo_phan")}>
          Bộ phận {sortField === "bo_phan" && (sortOrder === "asc" ? "↑" : "↓")}
        </a>
      ),
      dataIndex: "bo_phan",
      key: "bo_phan",
    },
    {
      title: (
        <a onClick={() => handleSort("phong_ban")}>
          Phòng ban{" "}
          {sortField === "phong_ban" && (sortOrder === "asc" ? "↑" : "↓")}
        </a>
      ),
      dataIndex: "phong_ban",
      key: "phong_ban",
    },
    {
      title: "Giới tính",
      dataIndex: "gender",
      key: "gender",
      render: (value) => {
        const gender = GENDER.find((g) => g.value === value);
        return gender ? gender.label : "Không xác định";
      },
    },
    { title: "Nơi ở", dataIndex: "address", key: "address" },
    {
      title: "Ngày vào công ty", // Tiêu đề cột mới
      dataIndex: "start_date", // Tên trường dữ liệu từ API
      key: "start_date",
      render: (value) =>
        value ? moment(value).format("DD/MM/YYYY") : "Không xác định",
    },
    {
      title: "Tùy chọn",
      key: "option",
      render: (text, record) => (
        <>
          <Button type="primary" style={{ marginLeft: 8 }}>
            <Link to={`/admin/employees-edit/${record.id}`}>Sửa</Link>
          </Button>
        </>
      ),
    },
  ];

  const rowClassName = (record) => {
    return cx({
      "row-new": record.status === "1", // Thử việc
      "row-active": record.status === "2", // Chính thức
      "row-inactive": record.status === "3", // Nghỉ việc
    });
  };

  return (
    <Layout>
      <Content style={{ margin: "15px" }}>
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: "16px" }}
        >
          <Col>
            <h2>Danh sách nhân viên</h2>
          </Col>
          <Col>
            <Button type="primary" as={Link} to="/admin/employee/new">
              Thêm mới
            </Button>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginBottom: "16px" }}>
          <Col span={6}>
            <Select
              placeholder="Trạng thái"
              style={{ width: "100%" }}
              onChange={(value) => setStatus(value)}
              allowClear
            >
              {STATUS.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={6}>
            <DatePicker
              placeholder="Ngày vào công ty"
              style={{ width: "100%" }}
              onChange={(date) => setStartDate(date)}
            />
          </Col>
        </Row>
        <Spin spinning={loading}>
          {employees.length > 0 ? (
            <Table
              dataSource={employees}
              columns={columns}
              rowKey="id"
              rowClassName={rowClassName}
              pagination={false}
            />
          ) : (
            <p>Không có dữ liệu</p>
          )}
        </Spin>
        <Pagination
          current={page}
          pageSize={perPage}
          total={total}
          onChange={handlePageChange}
          style={{ marginTop: "16px", textAlign: "center" }}
        />
        <EmployeeDetailModal
          isVisible={isModalOpen}
          employeeId={selectedEmployeeId}
          onClose={handleCloseModal}
        />
      </Content>
    </Layout>
  );
};

export default AdminEmployees;
