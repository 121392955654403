import React, { useState, useEffect } from "react";
import { Tabs, Form, Input, Button, Radio, Row, Col, Upload, Avatar, message } from "antd";
import { MailOutlined, PhoneOutlined, UserOutlined, EnvironmentOutlined, UploadOutlined } from "@ant-design/icons";
import { getProfile, updateUserById } from "api/user/account.api"; // Giả sử API này trả về thông tin user

const { TabPane } = Tabs;

const Profile = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [avatar, setAvatar] = useState(null);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [userId, setUserId] = useState(null); // Thêm state để lưu ID người dùng

    // Gọi API getProfile khi component mount
    useEffect(() => {
        const fetchProfile = async () => {
            setLoading(true);
            try {
                const response = await getProfile(); // Giả sử hàm này trả về dữ liệu profile của user
                console.log("Check data info: ", response);

                // Cập nhật giá trị form với dữ liệu user
                form.setFieldsValue({
                    email: response.email,
                    last_name: response.last_name,
                    first_name: response.first_name,
                    phone_number: response.phone_number,
                    address: response.address || "",
                });

                setUserId(response.id); // Lưu ID của người dùng để dùng trong update
                // Nếu có avatar, thiết lập avatar
                if (response.avatar) {
                    setAvatar(response.avatar);
                }
            } catch (error) {
                message.error("Lỗi khi tải thông tin người dùng");
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, [form]);

    const handleSave = async (values) => {
        setLoading(true);
        try {
            // Gọi API cập nhật dữ liệu profile người dùng
            const response = await updateUserById(userId, { // Gọi API với userId và các giá trị từ form
                first_name: values.first_name || "",
                last_name: values.last_name || "",
                phone_number: values.phone_number || "",
                address: values.address || "",
            });

            if (response.success) {
                message.success("Cập nhật thông tin thành công!");
            } else {
                message.error("Cập nhật thông tin thất bại.");
            }
        } catch (error) {
            console.error("Save failed: ", error);
            message.error("Đã xảy ra lỗi khi cập nhật thông tin.");
        } finally {
            setLoading(false);
        }
    };

    const handleUpload = ({ file }) => {
        setUploadLoading(true);
        // Giả lập quá trình tải lên
        setTimeout(() => {
            setAvatar(URL.createObjectURL(file));
            message.success("Tải lên ảnh đại diện thành công!");
            setUploadLoading(false);
        }, 1000);
    };

    return (
        <div style={{ padding: "20px", margin: "20px 0", background: "#fff", borderRadius: "8px", maxWidth: '70%' }}>
            <Tabs defaultActiveKey="1" tabPosition="top" style={{ textAlign: "left" }}>
                <TabPane tab="Thông tin tài khoản" key="1">
                    <div style={{ height: "500px", overflowY: "auto", paddingRight: "10px" }}>
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={handleSave}
                            initialValues={{
                                first_name: "",
                                last_name: "",
                                phone_number: "",
                                address: ""
                            }}
                        >
                            <h3>Thông tin cá nhân</h3>
                            <Row gutter={5}>
                                <Col span={8}>
                                    <Form.Item label="Email" name="email">
                                        <Input prefix={<MailOutlined />} disabled />
                                        <p style={{ fontSize: "12px", color: "#aaa" }}>E-mail này dùng để đăng nhập và không thể thay đổi</p>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Họ" name="last_name" rules={[{ required: true, message: "Vui lòng nhập họ và tên" }]}>
                                        <Input prefix={<UserOutlined />} placeholder="Nhập họ" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Tên" name="first_name" rules={[{ required: true, message: "Vui lòng nhập tên" }]}>
                                        <Input prefix={<UserOutlined />} placeholder="Nhập tên" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={5}>
                                <Col span={8}>
                                    <Form.Item label="Điện thoại" name="phone_number" rules={[{ required: true, message: "Vui lòng nhập số điện thoại" }]}>
                                        <Input prefix={<PhoneOutlined />} placeholder="Nhập số điện thoại" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Địa chỉ" name="address" rules={[{ required: true, message: "Vui lòng nhập địa chỉ" }]}>
                                        <Input prefix={<EnvironmentOutlined />} placeholder="Nhập địa chỉ" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Button type="primary" htmlType="submit" block loading={loading}>
                                Lưu thay đổi
                            </Button>
                        </Form>
                    </div>
                </TabPane>

                <TabPane tab="Cập nhật Avatar" key="2">
                    <div style={{ textAlign: 'left' }}>
                        <Avatar size={128} src={avatar} icon={<UserOutlined />} style={{ marginRight: '20px' }} />
                        <Upload showUploadList={false} customRequest={handleUpload}>
                            <Button icon={<UploadOutlined />} loading={uploadLoading} style={{ marginTop: 16 }}>
                                {uploadLoading ? "Đang tải lên..." : "Chọn ảnh đại diện"}
                            </Button>
                        </Upload>
                    </div>
                </TabPane>

                <TabPane tab="Đổi mật khẩu" key="3">
                    {/* Form đổi mật khẩu */}
                </TabPane>
            </Tabs>
        </div>
    );
};

export default Profile;
