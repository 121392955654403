import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "page/Auth/Auth.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { register } from "api/auth/authen.api";
import { USER_PATH } from "constant/appPath";
import { SIGN_IN_PATH, PAGE_404_PATH, AUTH_PATH } from "constant/appPath";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
} from "antd";
import ErrorAlert from "components/ErrorAlert";

const cx = classNames.bind(styles);

const urlLogin = `${AUTH_PATH}/${SIGN_IN_PATH}`;

const Register = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleTogglePasswordConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };

  const handleSubmit = async (values) => {
    const { firstName, lastName, phoneNumber, email, password, passwordConfirmation } = values;
    try {
      const role = "user";
      const response = await register(
        firstName,
        lastName,
        phoneNumber,
        email,
        password,
        passwordConfirmation,
        role
      );
      navigate(USER_PATH);
      console.log(response);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 422) {
        const errors = error.response.data.errors;
        setErrors(errors);
      } else {
        navigate(PAGE_404_PATH);
      }
    }
  };

  return (
    <div className={cx("container-account")}>
      <Row className={cx("row-account")}>
        <Col span={24}>
          <h1 className={cx("account-text")}>CREATE AN ACCOUNT</h1>
          {errors.length > 0 && <ErrorAlert errors={errors} />}
        </Col>
      </Row>
      <Form form={form} onFinish={handleSubmit}>
        <Row className={cx("auth-form-item")}>
          <Col span={24}>
            <Form.Item
              name="firstName"
              rules={[{ required: true, message: "Please enter your first name" }]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row className={cx("auth-form-item")}>
          <Col span={24}>
            <Form.Item
              name="lastName"
              rules={[{ required: true, message: "Please enter your last name" }]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row className={cx("auth-form-item")}>
          <Col span={24}>
            <Form.Item
              name="phoneNumber"
              rules={[{ required: true, message: "Please enter your phone number" }]}
            >
              <Input placeholder="Phone Number" />
            </Form.Item>
          </Col>
        </Row>
        <Row className={cx("auth-form-item")}>
          <Col span={24}>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please enter your email" },
                { type: "email", message: "Please enter a valid email" }
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
        </Row>
        <Row className={cx("auth-form-item")}>
          <Col span={24}>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please enter your password" },
                { min: 8, message: "Password must be at least 8 characters long" }
              ]}
            >
              <Input.Password
                type={showPassword ? "text" : "password"}
                placeholder="Enter password"
                iconRender={visible => (visible ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />)}
                onClick={handleTogglePassword}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className={cx("auth-form-item")}>
          <Col span={24}>
            <Form.Item
              name="passwordConfirmation"
              dependencies={['password']}
              rules={[
                { required: true, message: "Please confirm your password" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Passwords do not match'));
                  },
                }),
              ]}
            >
              <Input.Password
                type={showPasswordConfirm ? "text" : "password"}
                placeholder="Confirm password"
                iconRender={visible => (visible ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />)}
                onClick={handleTogglePasswordConfirm}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className={cx("auth-form-item")}>
          <Col span={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Register
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row className={cx("auth-form-item")}>
          <Col span={24}>
            <p className={cx("row-to-sign-in")}>
              Bạn đã có tài khoản?{" "}
              <Link to={urlLogin} className={cx("text-sign-in")}>
                Đăng nhập
              </Link>
            </p>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Register;
