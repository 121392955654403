import { jwtDecode } from "jwt-decode";

/**
 * Kiểm tra token còn hạn hay không
 * @param {string} token - Mã JWT
 * @returns {boolean} - `true` nếu token còn hạn, `false` nếu hết hạn
 */
export const isTokenValid = (token) => {
    if (!token) return false;
    try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Lấy thời gian hiện tại tính bằng giây
        return decoded.exp > currentTime;
    } catch (error) {
        console.error("Lỗi kiểm tra token:", error);
        return false;
    }
};