import React, { useEffect, useState } from "react";
import { Modal, Tabs, Spin, message, Row, Col, Button } from "antd";
import { getEmployeeDetails } from "api/admin/employees.api"; // Import API

const { TabPane } = Tabs;

const EmployeeDetailModal = ({ isVisible, employeeId, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState(null);

  useEffect(() => {
    if (isVisible && employeeId) {
      const fetchEmployeeDetails = async () => {
        setLoading(true);
        try {
          const details = await getEmployeeDetails(employeeId);
          console.log("Check data nhân viên: ", details);
          setEmployee(details);
        } catch (error) {
          message.error("Không thể tải chi tiết nhân viên: " + error.message);
          setEmployee(null);
        } finally {
          setLoading(false);
        }
      };

      fetchEmployeeDetails();
    }
  }, [isVisible, employeeId]);

  const renderPersonalInfo = () => (
    <div
      style={{
        padding: "16px",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
      }}
    >
      <p style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "8px" }}>
        Thông tin cơ bản
      </p>
      <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
        <Col span={24} style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "80px",
              height: "80px",
              borderRadius: "50%",
              overflow: "hidden",
              marginRight: "16px",
            }}
          >
            <img
              src={employee?.card_photo || "https://via.placeholder.com/80"}
              alt="Profile"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </div>
          <div>
            <p
              style={{ fontSize: "16px", fontWeight: "bold", margin: 0 }}
            >{`${employee?.last_name} ${employee?.first_name}`}</p>
            <p style={{ margin: 0, color: "#666" }}>{employee?.address}</p>
          </div>
        </Col>
        <Col span={24}>
          <div style={{ borderTop: "1px solid #eaeaea", paddingTop: "16px" }}>
            <p style={{ marginBottom: "8px" }}>
              <strong>Tên:</strong>{" "}
              {`${employee?.last_name} ${employee?.first_name}`}
            </p>
            <p style={{ marginBottom: "8px" }}>
              <strong>Ngày sinh:</strong>{" "}
              {employee?.birthday_date || "Không xác định"}
            </p>
            <p style={{ marginBottom: "8px" }}>
              <strong>Giới tính:</strong>{" "}
              {employee?.gender === "M" ? "Nam" : "Nữ"}
            </p>
            <p style={{ marginBottom: "8px" }}>
              <strong>Điện thoại:</strong> {employee?.phone || "Không xác định"}
            </p>
            <p style={{ marginBottom: "8px" }}>
              <strong>Email:</strong> {employee?.email || "Không xác định"}
            </p>
            <p style={{ marginBottom: "8px" }}>
              <strong>Số CCCD:</strong>{" "}
              {employee?.identity_number || "Không xác định"}
            </p>
            <p style={{ marginBottom: "8px" }}>
              <strong>Mã số thuế:</strong>{" "}
              {employee?.tax_code || "Không xác định"}
            </p>
            <p style={{ marginBottom: "8px" }}>
              <strong>Mã số BHXH:</strong>{" "}
              {employee?.insurance_code || "Không xác định"}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );

  const renderWorkInfo = () => (
    <div
      style={{
        padding: "16px",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
      }}
    >
      <p style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "8px" }}>
        Thông tin công việc
      </p>
      <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
        <Col span={24} style={{ display: "flex", alignItems: "center" }}>
          <div>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                margin: 0,
                marginBottom: "8px",
              }}
            >
              Mã số nhân viên: {employee?.employee_id}
            </p>
            <p style={{ margin: 0, color: "#666", marginBottom: "8px" }}>
              <strong>Chức vụ:</strong>{" "}
              {employee?.position_name || "Không xác định"}
            </p>
            <p style={{ margin: 0, color: "#666", marginBottom: "8px" }}>
              <strong>Phòng ban:</strong>{" "}
              {employee?.department_name || "Không xác định"}
            </p>
            <p style={{ margin: 0, color: "#666", marginBottom: "8px" }}>
              <strong>Bộ phận:</strong>{" "}
              {employee?.division_name || "Không xác định"}
            </p>
            <p style={{ margin: 0, color: "#666", marginBottom: "8px" }}>
              <strong>Ngày làm vào công ty:</strong>{" "}
              {employee?.start_date || "Không xác định"}
            </p>
          </div>
        </Col>
        <Col span={24}>
          <div style={{ borderTop: "1px solid #eaeaea", paddingTop: "16px" }}>
            <p style={{ marginBottom: "8px" }}>
              <strong>Thông tin khác ... </strong>{" "}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );

  const renderBankInfo = () => (
    <div
      style={{
        padding: "16px",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
      }}
    >
      <p style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "8px" }}>
        Thông tin tài khoản ngân hàng
      </p>
      <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
        <Col span={24} style={{ display: "flex", alignItems: "center" }}>
          <div>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                margin: 0,
                marginBottom: "8px",
              }}
            >
              Mã số tài khoản: {employee?.account_number}
            </p>
            <p style={{ margin: 0, color: "#666", marginBottom: "8px" }}>
              <strong>Tên ngân hàng:</strong>{" "}
              {employee?.bank_name || "Không xác định"}
            </p>
            <p style={{ margin: 0, color: "#666", marginBottom: "8px" }}>
              <strong>Tên tài khoản:</strong>{" "}
              {employee?.account_holder || "Không xác định"}
            </p>
          </div>
        </Col>
        <Col span={24}>
          <div style={{ borderTop: "1px solid #eaeaea", paddingTop: "16px" }}>
            <p style={{ marginBottom: "8px" }}>
              <strong>Thông tin khác ... </strong>{" "}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );

  const renderLoginInfo = () => (
    <div
      style={{
        padding: "16px",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
      }}
    >
      <p style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "8px" }}>
        Thông tin tài khoản hệ thống
      </p>
      <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
        <Col span={24} style={{ display: "flex", alignItems: "center" }}>
          <div>
            <p style={{ margin: 0, color: "#666", marginBottom: "8px" }}>
              <strong>Tên đăng nhập:</strong>{" "}
              {employee?.username || "Không xác định"}
            </p>
            <p style={{ margin: 0, color: "#666", marginBottom: "8px" }}>
              <strong>Mật khẩu: ******</strong>
            </p>
            <p style={{ margin: 0, color: "#666", marginBottom: "8px" }}>
              <strong>Nhóm quyền:</strong>{" "}
              {employee?.groups || "Không xác định"}
            </p>
          </div>
        </Col>
        <Col span={24}>
          <div style={{ borderTop: "1px solid #eaeaea", paddingTop: "16px" }}>
            <p style={{ marginBottom: "8px" }}>
              <strong>Thông tin khác ... </strong>{" "}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );

  return (
    <Modal
      title="Chi tiết nhân viên"
      visible={isVisible}
      footer={[
        <Button key="close" type="primary" onClick={onClose}>
          Đóng
        </Button>,
      ]}
      closable={false}
      width={800} // Đặt chiều rộng của modal
    >
      <Spin spinning={loading}>
        {employee ? (
          <Tabs defaultActiveKey="1">
            <TabPane tab="Thông tin cá nhân" key="1">
              {renderPersonalInfo()}
            </TabPane>
            <TabPane tab="Thông tin công việc" key="2">
              {renderWorkInfo()}
            </TabPane>
            <TabPane tab="Tài khoản ngân hàng" key="3">
              {renderBankInfo()}
            </TabPane>
            <TabPane tab="Thông tin đăng nhập" key="4">
              {renderLoginInfo()}
            </TabPane>
          </Tabs>
        ) : (
          <p>Không có dữ liệu nhân viên</p>
        )}
      </Spin>
    </Modal>
  );
};

export default EmployeeDetailModal;
