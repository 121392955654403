import axiosClient from "api/config/axiosAdmin";

const getListAllUser = (perPage, page) => {
  const url = "admin/users";
  return axiosClient.get(url, {
    perPage,
    page,
  });
};

const addNewUser = (
  username,
  email,
  password,
  role,
  first_name,
  last_name,
  nickname,
  phone_number,
  address
) => {
  const url = "admin/users";
  return axiosClient.post(url, {
    username,
    email,
    password,
    role,
    first_name,
    last_name,
    nickname,
    phone_number,
    address,
  });
};

const deleteUser = (id) => {
  const url = `/admin/users/${id}`;
  return axiosClient.delete(url);
};

const getUserById = (id) => {
  const url = `/admin/users/${id}`;
  return axiosClient.get(url);
};

const updateUserById = (
  id,
  username,
  email,
  password,
  role,
  first_name,
  last_name,
  nickname,
  phone_number,
  address
) => {
  const url = `/admin/users/${id}`;
  return axiosClient.put(url, {
    username,
    email,
    password,
    role,
    first_name,
    last_name,
    nickname,
    phone_number,
    address,
  });
};

const getListAllRole = () => {
  const url = "admin/role";
  return axiosClient.get(url);
};

export { getListAllUser, addNewUser, deleteUser, getUserById, updateUserById, getListAllRole };
