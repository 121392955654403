import React from "react";
import { Layout, ConfigProvider, theme } from "antd";
import AdminHeader from "layouts/AdminLayout/AdminHeader";
import LeftSidebarMenu from "layouts/AdminLayout/LeftSidebarMenu";
import { Helmet } from 'react-helmet';
// import CustomBreadcrumb from "components/CustomBreadcrumb";

import { Outlet } from "react-router-dom";

const { Footer } = Layout;

const Admin = () => {

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <ConfigProvider>
      <Helmet>
        <title>MDLand - Web App Quản lý</title>
      </Helmet>
      <Layout style={{ minHeight: "100vh" }}>
        <LeftSidebarMenu />
        <Layout>
          <AdminHeader colorBgContainer={colorBgContainer} />
          {/* <CustomBreadcrumb /> */}
          <Outlet />
          <Footer style={{ textAlign: "center" }}>
            MDLand ©{new Date().getFullYear()} Created by DungPT
          </Footer>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default Admin;
