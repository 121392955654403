import axios from "axios";
import { ADMIN_PATH_LOGIN } from "constant/authPath";

const TIME_OUT = 20000;

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

const axiosAdmin = axios.create({
  baseURL: apiUrl, // Replace with your actual API endpoint
  timeout: TIME_OUT,
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to handle configuring headers for file uploads
const configureFileUploadHeaders = (config) => {
  config.headers = {
    "Content-Type": "multipart/form-data",
  };
  return config;
};

// Request interceptor - Check for file uploads and set headers accordingly
axiosAdmin.interceptors.request.use(
  (config) => {
    const hasFile = config.data instanceof FormData; // Check if data is FormData (indicating file upload)
    if (hasFile) {
      configureFileUploadHeaders(config);
    }

    let authenticationData = localStorage.getItem("adminToken");

    if (authenticationData) {
      config.headers["authorization"] = "Bearer " + authenticationData;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor remains the same
axiosAdmin.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log("check lỗi hệ thống admin: ", error);
    if (error.response.status === 401 || error.response.status === 403) {
      if (error.response.data.status_code === "LOGIN_NG") {
        return Promise.reject(error.response.data);
      } else {
        localStorage.removeItem("adminToken");
        localStorage.removeItem("adminData");
        window.location.href = ADMIN_PATH_LOGIN;
      }
    } else if (
      error.code === "ERR_BAD_REQUEST" ||
      error.response.status === 500 ||
      error.response.status === 400
    ) {
      return Promise.reject(error);
    } else {
      window.location.href = "/404.html";
    }
  }
);

export default axiosAdmin;
