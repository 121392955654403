// Thiết định phân trang
export const PAGE_NUMBER = 1;
export const PAGE_SIZE = 10;
export const PAGE_SIZE_PROJECT = 5;

// Thiết định giới tính
export const GENDER = [
  {
    value: "1",
    label: "Nam",
  },
  {
    value: "2",
    label: "Nữ",
  },
  {
    value: "3",
    label: "Khác",
  },
];
