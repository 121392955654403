import axiosClient from "api/config/axiosClient";

/**
 * Get chi tiết thông tin user
 * 
 * @author DungPT
 * @returns object user
 */
const getProfile = () => {
    const url = "/user/profile";
    return axiosClient.get(url);
}

const updateUserById = (id, first_name, last_name, phone_number, address) => {
    const url = `/user/update/${id}`;
    return axiosClient.put(url, { first_name, last_name, phone_number, address });
};

export {
    getProfile,
    updateUserById
}