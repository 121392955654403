import axiosAdmin from "api/config/axiosAdmin";

/**
 * Gọi API lấy toàn bộ user
 *
 * @author DungPT
 * @returns
 */
const getListAllUser = (groupId) => {
  const url = `/admin/get-list-user-not-group/${groupId}`;
  return axiosAdmin.get(url);
};

/**
 * Gọi API lấy toàn bộ group
 *
 * @author DungPT
 * @returns
 */
const getListAllGroup = () => {
  const url = "admin/groups";
  return axiosAdmin.get(url);
};

/**
 * Gọi API lấy toàn Role và Resource
 *
 * @author DungPT
 * @returns
 */
const getListAllRoleResources = () => {
  const url = "admin/roles-resources";
  return axiosAdmin.get(url);
};

/**
 * Tạo mới nhóm quyền
 *
 * @author DungPT
 * @param {string} name
 * @param {string} description
 * @returns
 */
const addUserGroup = (name, description) => {
  const url = "admin/groups";
  return axiosAdmin.post(url, {
    name,
    description,
  });
};

/**
 * Chỉnh sửa nhóm quyền
 *
 * @author DungPT
 * @param {int} groupId
 * @param {string} name
 * @param {string} description
 * @returns
 */
const editUserGroup = (groupId, name, description) => {
  const url = `admin/groups/${groupId}`;
  return axiosAdmin.put(url, {
    name,
    description,
  });
};

/**
 * Gọi API lấy phân quyền của 1 group
 *
 * @author DungPT
 * @param {Int} groupId
 * @returns
 */
const getGroupPermissionByGroupId = (groupId) => {
  const url = `admin/group-permissions/${groupId}`;
  return axiosAdmin.get(url);
};

/**
 * Lưu quyền cho group
 *
 * @author DungPT
 * @param {Int} groupId
 * @param {object} permissions
 * @returns
 */
const saveGroupPermistion = (groupId, permissions) => {
  const url = "admin/group-permissions";
  return axiosAdmin.post(url, { group_id: groupId, permissions });
};

/**
 * Call API lấy danh sách user theo 1 group
 *
 * @author DungPT
 * @param {int} groupId
 * @returns
 */
const getListUserByGroupId = (groupId) => {
  const url = `/admin/get-list-user-by-group-id/${groupId}`;
  return axiosAdmin.get(url);
};

/**
 * Call API lưu user vào group
 *
 * @author DungPT
 * @param {int} groupId
 * @param {Array} users
 * @returns
 */
const updateGroupUsers = (groupId, users) => {
  const url = "/admin/group-users";
  return axiosAdmin.post(url, {
    group_id: groupId,
    user_ids: users,
  });
};

/**
 * Xóa user khỏi group
 *
 * @author DungPT
 * @param {int} groupId
 * @param {int} userId
 * @returns
 */
const deleteGroupUsers = (groupId, userId) => {
  const url = "/admin/delete-group-users";
  return axiosAdmin.post(url, {
    group_id: groupId,
    user_id: userId,
  });
};

export {
  getListAllUser,
  getListAllRoleResources,
  getGroupPermissionByGroupId,
  saveGroupPermistion,
  getListAllGroup,
  addUserGroup,
  editUserGroup,
  getListUserByGroupId,
  updateGroupUsers,
  deleteGroupUsers,
};
