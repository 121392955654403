import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "context/UserContext";
import {
  AUTH_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  HOMEPAGE,
  TOOL_PATH,
  PROJECT_ROW_CT_PATH,
  PROJECT_ROW_TT_PATH,
  PROJECT_NEW_PATH,
  NEW_PATH,
  NEW_POST_PATH,
  NEW_CATEGORY_PATH,
  SEARCH_PATH,
  APARTMENT_DETAIL_PATH,
  APARTMENT_LOW_RISE_DETAIL_PATH,
  RENT_POST_ADD_PATH,
  RENT_POST_PATH,
  USER_PATH,
  USER_PROFILE
} from "constant/appPath";
import Application from "page/Application";
import Home from "page/Application/Home";
import Auth from "page/Auth";
import Register from "page/Auth/Register";
import SignIn from "page/Auth/SignIn";
import Account from "page/Account";
import Tool from "page/Application/Tool";
import ProjectRow from "page/Application/ProjectRow";
import ProjectNew from "page/Application/ProjectNew";
import New from "page/Application/New";
import NewCategory from "page/Application/NewCategory";
import NewPost from "page/Application/NewPost";
import SearchResultsPage from "page/Application/SearchResultsPage";
import ApartmentDetail from "page/Application/ApartmentDetail";
import ProjectLowRise from "page/Application/ProjectLowRise";
import ApartmentLowRiseDetail from "page/Application/ApartmentLowRiseDetail";
import RentPostAdd from "page/Application/RentPostAdd";
import RentPostList from "page/Application/RentPostList";
import Profile from "page/Account/Profile";

const PrivateRoute = ({ element: Element, ...rest }) => {
  let flgLogin = false;
  const { user } = useContext(UserContext);
  if (user) {
    flgLogin = true;
  }
  const userToken = localStorage.getItem("userToken");
  if (userToken) {
    flgLogin = true;
  }
  return flgLogin ? (
    <Element {...rest} />
  ) : (
    <Navigate to={`${AUTH_PATH}/${SIGN_IN_PATH}`} />
  );
};

const appRouter = [
  {
    element: <Application />,
    path: HOMEPAGE,
    children: [
      {
        element: <Home />,
        path: "",
      },
      {
        element: <PrivateRoute element={Tool} />,
        path: TOOL_PATH,
      },
      {
        element: <ProjectRow />,
        path: PROJECT_ROW_CT_PATH,
      },
      {
        element: <ProjectLowRise />,
        path: PROJECT_ROW_TT_PATH,
      },
      {
        element: <ProjectNew />,
        path: PROJECT_NEW_PATH,
      },
      {
        element: <New />,
        path: NEW_PATH,
      },
      {
        element: <NewCategory />,
        path: NEW_CATEGORY_PATH,
      },
      {
        element: <NewPost />,
        path: NEW_POST_PATH,
      },
      {
        element: <SearchResultsPage />,
        path: SEARCH_PATH,
      },
      {
        element: <ApartmentDetail />,
        path: APARTMENT_DETAIL_PATH,
      },
      {
        element: <ApartmentLowRiseDetail />,
        path: APARTMENT_LOW_RISE_DETAIL_PATH,
      },
      {
        element: <PrivateRoute element={RentPostAdd} />,
        path: RENT_POST_ADD_PATH,
      },
      {
        element: <RentPostList />,
        path: RENT_POST_PATH,
      },
    ],
  },
  {
    element: <Auth />,
    path: AUTH_PATH,
    children: [
      {
        element: <SignIn />,
        path: SIGN_IN_PATH,
      },
      {
        element: <Register />,
        path: SIGN_UP_PATH,
      },
    ],
  },
  {
    element: <PrivateRoute element={Account} />,
    path: USER_PATH,
    children: [
      {
        element: <Profile />,
        path: USER_PATH,
      },
    ],
  },
];

export default appRouter;
