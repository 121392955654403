import React, { useState, useEffect } from "react";
import { Modal, Input, Spin, message } from "antd";

import { addUserGroup, editUserGroup } from "api/admin/permission.api";

const GroupModal = ({
  title,
  visible,
  onSuccess,
  onCancel,
  initialGroupId = null, // ID nhóm khi chỉnh sửa
  initialGroupName = "",
  initialDescription = "",
}) => {
  const [groupName, setGroupName] = useState(initialGroupName);
  const [description, setDescription] = useState(initialDescription);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Reset dữ liệu khi modal mở lại
    if (visible) {
      setGroupName(initialGroupName);
      setDescription(initialDescription);
    }
  }, [visible, initialGroupName, initialDescription]);

  const handleOk = async () => {
    if (!groupName.trim()) {
      message.error("Tên nhóm không được để trống");
      return;
    }

    setLoading(true);
    try {
      if (initialGroupId) {
        // Chế độ chỉnh sửa
        await editUserGroup(initialGroupId, groupName, description);
        message.success("Chỉnh sửa nhóm thành công");
      } else {
        // Chế độ tạo mới
        await addUserGroup(groupName, description);
        message.success("Tạo nhóm mới thành công");
      }
      onSuccess(); // Notify parent to refresh data or handle success
    } catch (error) {
      message.error(
        `Lỗi: ${
          initialGroupId ? "Chỉnh sửa nhóm" : "Tạo nhóm mới"
        } không thành công. ${error.message || "Vui lòng thử lại"}`
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      okText="Lưu"
      cancelText="Hủy bỏ"
      confirmLoading={loading}
    >
      <Spin spinning={loading} tip="Processing...">
        <div style={{ marginBottom: "1rem" }}>
          <label style={{ fontWeight: "bold" }}>Tên nhóm:</label>
          <Input
            placeholder="Nhập tên nhóm quyền"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            disabled={loading}
          />
        </div>
        <div style={{ marginBottom: "1rem" }}>
          <label style={{ fontWeight: "bold" }}>Mô tả:</label>
          <Input.TextArea
            placeholder="Mô tả chi tiết nhóm quyền"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            disabled={loading}
            rows={4}
          />
        </div>
      </Spin>
    </Modal>
  );
};

export default GroupModal;
