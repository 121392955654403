import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Row,
  Col,
  Typography,
  DatePicker,
  Upload,
  Tabs,
  message,
  Spin,
} from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  addNewEmployees,
  getNextImployeesId,
  getEmployeeDetails,
  updateEmployees,
} from "api/admin/employees.api";
import {
  getListAllDepartment,
  getListAllDivision,
  getListAllPositions,
} from "api/admin/master.api";
import { getListAllGroup } from "api/admin/permission.api";
import { removeVietnameseTones } from "common/uti";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { uploadFileImage, deleteFileImage } from "api/common/upload.api";

const { Title } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const AdminEmployeesNew = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const isEditMode = !!id;

  // States for dynamic dropdown options
  const [departments, setDepartments] = useState([]);
  const [positions, setPositions] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiErrors, setApiErrors] = useState([]);
  const [layoutImage, setLayoutImage] = useState(null); // State for layout image

  useEffect(() => {
    // Fetch dropdown data on component mount
    fetchDropdownData();

    if (isEditMode) {
      fetchData();
    } else {
      handleValuesChange({});
    }
  }, [id]);

  const handleValuesChange = async (changedValues) => {
    const { lastName, firstName } = form.getFieldsValue();

    if (changedValues.lastName || changedValues.firstName) {
      const usernameStr = `${lastName || ""}${firstName || ""}`;

      const username = removeVietnameseTones(usernameStr);
      console.log("User name: ", usernameStr);
      form.setFieldsValue({ username: username });
    }

    if (
      !isEditMode &&
      (!changedValues.employeeId ||
        changedValues.lastName ||
        changedValues.firstName)
    ) {
      try {
        const response = await getNextImployeesId();
        if (response.nextEmployeeId) {
          const nextId = response.nextEmployeeId;
          const employeeId = `NV${String(nextId).padStart(3, "0")}`;
          form.setFieldsValue({ employeeId });
        } else {
          throw new Error("Invalid response format");
        }
      } catch (error) {
        console.error("Error generating employee ID:", error);
        message.error("Không thể tạo mã nhân viên tự động. Vui lòng thử lại.");
      }
    }
  };

  const fetchDropdownData = async () => {
    try {
      const [departmentRes, divisionRes, positionRes, groupRes] =
        await Promise.all([
          getListAllDepartment(),
          getListAllDivision(),
          getListAllPositions(),
          getListAllGroup(),
        ]);
      console.log("check quyền: ", groupRes);
      setDepartments(departmentRes || []);
      setDivisions(divisionRes || []);
      setPositions(positionRes || []);
      setGroups(groupRes || []);
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await getEmployeeDetails(id);
      form.setFieldsValue({
        ...response,
        employeeId: response.employee_id,
        lastName: response.last_name,
        firstName: response.first_name,
        phoneNumber: response.phone,
        phoneNumber: response.phone,
        birthDate: response.birthday_date
          ? moment(response.birthday_date)
          : null,
        identityNumber: response.identity_number,
        insuranceCode: response.insurance_code,
        taxCode: response.tax_code,
        startDate: response.start_date ? moment(response.start_date) : null,
        endDate: response.end_date ? moment(response.end_date) : null,
        bankName: response.bank_name,
        accountNumber: response.account_number,
        accountHolder: response.account_holder,
        group: response.group_id,
      });
      setLayoutImage(response.card_photo);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const generateEmployeeId = async () => {
    try {
      // Gọi API để lấy max(ID)
      const response = await getNextImployeesId();

      if (response.nextEmployeeId) {
        const nextId = response.nextEmployeeId;
        const employeeId = `NV${String(nextId).padStart(3, "0")}`;
        form.setFieldsValue({ employeeId });
      } else {
        throw new Error("Invalid response format");
      }
    } catch (error) {
      console.error("Error generating employee ID:", error);
      message.error("Không thể tạo mã nhân viên tự động. Vui lòng thử lại.");
    }
  };

  // Password Generator
  const generatePassword = () => {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@#$!";
    let password = "";
    for (let i = 0; i < 12; i++) {
      password += charset[Math.floor(Math.random() * charset.length)];
    }
    form.setFieldsValue({ password });
    message.success("Mật khẩu đã được tạo tự động!");
  };

  // Xử lý update data nhân viên
  const handleSubmit = async (values) => {
    setIsLoading(true); // Bật spinner
    setApiErrors([]); // Xóa lỗi cũ
    try {
      const transformedValues = {
        ...values,
        birthDate: values.birthDate
          ? values.birthDate.format("DD-MM-YYYY")
          : null,
        startDate: values.startDate
          ? values.startDate.format("DD-MM-YYYY")
          : null,
        endDate: values.endDate ? values.endDate.format("DD-MM-YYYY") : null,
      };

      const userData = {
        username: transformedValues.username,
        password: transformedValues.password,
        group: transformedValues.group,
      };

      const employeesData = {
        firstName: transformedValues.firstName,
        lastName: transformedValues.lastName,
        gender: transformedValues.gender,
        birthDate: transformedValues.birthDate,
        identityNumber: transformedValues.identityNumber,
        address: transformedValues.address,
        position: transformedValues.position,
        department: transformedValues.department,
        division: transformedValues.division,
        startDate: transformedValues.startDate,
        endDate: transformedValues.endDate,
        cardPhoto: layoutImage,
        taxCode: transformedValues.taxCode,
        insuranceCode: transformedValues.insuranceCode,
        employeeId: transformedValues.employeeId,
        phoneNumber: transformedValues.phoneNumber,
        email: transformedValues.email,
      };

      const bankData = {
        bankName: transformedValues.bankName,
        accountNumber: transformedValues.accountNumber,
        accountHolder: transformedValues.accountHolder,
      };

      if (isEditMode) {
        await updateEmployees(id, userData, employeesData, bankData);
      } else {
        await addNewEmployees(userData, employeesData, bankData);
      }

      message.success(
        isEditMode ? "Cập nhật thành công!" : "Thêm mới thành công!"
      );
      navigate(`/admin/employees`);
    } catch (error) {
      if (error.response?.data?.errors) {
        const apiErrorList = Object.keys(error.response.data.errors).map(
          (field) => `${error.response.data.errors[field][0]}`
        );

        setApiErrors(apiErrorList); // Lưu lỗi vào state

        // Gán lỗi chi tiết cho các trường
        const fieldErrors = Object.keys(error.response.data.errors).map(
          (field) => ({
            name: field,
            errors: [error.response.data.errors[field][0]],
          })
        );

        form.setFields(fieldErrors);
      } else {
        setApiErrors(["Đã xảy ra lỗi không mong muốn."]);
      }
    } finally {
      setIsLoading(false); // Tắt spinner
    }
  };

  // Upload ảnh đại diện
  const handleImageUpload = async ({ file }) => {
    try {
      const response = await uploadFileImage(file);
      if (response && response.url) {
        setLayoutImage(response.url); // Set the layout image with the returned URL
        message.success("Ảnh đã được tải lên thành công.");
      } else {
        message.error("Tải ảnh lên không thành công.");
      }
    } catch (error) {
      message.error("Lỗi khi tải ảnh lên.");
    }
  };

  const handleImageDelete = async () => {
    try {
      if (layoutImage) {
        console.log("Check file: ", layoutImage);
        const response = await deleteFileImage(layoutImage); // Call API to delete image
        if (response && response.success) {
          setLayoutImage(null); // Reset the layout image state
          message.success("Ảnh đã được xóa thành công.");
        } else {
          message.error("Xóa ảnh không thành công.");
        }
      } else {
        message.error("Không có ảnh để xóa.");
      }
    } catch (error) {
      message.error("Lỗi khi xóa ảnh.");
    }
  };

  return (
    <Spin spinning={isLoading} tip="Đang xử lý...">
      <div style={{ padding: 24 }}>
        {apiErrors.length > 0 && (
          <div
            style={{
              backgroundColor: "#f8d7da",
              color: "#721c24",
              border: "1px solid #f5c6cb",
              padding: "10px",
              borderRadius: "5px",
              marginBottom: "16px",
            }}
          >
            <strong>Đã xảy ra lỗi:</strong>
            <ul>
              {apiErrors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </div>
        )}
        <Row justify="center">
          <Col span={20}>
            <Title level={2}>
              {isEditMode
                ? "Cập nhật thông tin nhân viên"
                : "Thêm mới nhân viên"}
            </Title>
            <Form
              form={form}
              layout="vertical"
              onFinish={handleSubmit}
              onValuesChange={handleValuesChange}
            >
              <Tabs defaultActiveKey="1">
                <TabPane tab="Thông tin cá nhân" key="1">
                  <Row gutter={16}>
                    <Col span={8} style={{ borderRight: "1px solid #ddd" }}>
                      <Form.Item
                        name="lastName"
                        label="Họ và tên đệm"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng nhập họ và tên đệm!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="firstName"
                        label="Tên"
                        rules={[
                          { required: true, message: "Vui lòng nhập tên!" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="phoneNumber"
                        label="Số điện thoại"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng nhập số điện thoại!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                          { type: "email", message: "Email không hợp lệ!" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="gender"
                        label="Giới tính"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng chọn giới tính!",
                          },
                        ]}
                      >
                        <Select placeholder="Chọn giới tính">
                          <Option value="1">Nam</Option>
                          <Option value="2">Nữ</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="birthDate"
                        label="Ngày sinh"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng chọn ngày sinh!",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          format="DD/MM/YYYY" // Định dạng ngày
                          allowClear // Cho phép xóa giá trị
                          placeholder="Nhập ngày định dạng DD/MM/YYYY" // Placeholder
                          onChange={(date, dateString) => {
                            console.log("Selected Date:", dateString); // Xử lý nếu cần
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="address"
                        label="Địa chỉ"
                        rules={[
                          { required: true, message: "Vui lòng nhập địa chỉ!" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={8} style={{ borderRight: "1px solid #ddd" }}>
                      <Form.Item
                        name="identityNumber"
                        label="Số CCCD"
                        rules={[
                          { required: true, message: "Vui lòng nhập số CCCD!" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item name="taxCode" label="Mã số thuế">
                        <Input />
                      </Form.Item>
                      <Form.Item name="insuranceCode" label="Mã số BHXH">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Ảnh thẻ">
                        <Upload
                          customRequest={handleImageUpload}
                          listType="picture"
                          maxCount={1}
                          accept="image/*"
                          showUploadList={false}
                        >
                          <Button icon={<UploadOutlined />}>Tải ảnh</Button>
                        </Upload>
                        {layoutImage && (
                          <>
                            <img
                              src={layoutImage}
                              alt="Ảnh thẻ"
                              style={{ paddingTop: "10px", maxWidth: "100%" }}
                            />
                            <Button
                              type="link"
                              icon={<DeleteOutlined />}
                              onClick={handleImageDelete}
                              style={{ color: "red" }}
                            >
                              Xóa ảnh
                            </Button>
                          </>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tab="Thông tin công việc" key="2">
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item name="employeeId" label="Mã nhân viên">
                        <Input disabled />
                      </Form.Item>
                      <Form.Item
                        name="position"
                        label="Chức vụ"
                        rules={[
                          { required: true, message: "Vui lòng chọn chức vụ!" },
                        ]}
                      >
                        <Select placeholder="Chọn chức vụ">
                          {positions.map((position) => (
                            <Option key={position.id} value={position.id}>
                              {position.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="department"
                        label="Phòng ban"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng chọn phòng ban!",
                          },
                        ]}
                      >
                        <Select placeholder="Chọn phòng ban">
                          {departments.map((department) => (
                            <Option key={department.id} value={department.id}>
                              {department.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="division"
                        label="Bộ phận"
                        rules={[
                          { required: true, message: "Vui lòng chọn bộ phận!" },
                        ]}
                      >
                        <Select placeholder="Chọn bộ phận">
                          {divisions.map((division) => (
                            <Option key={division.id} value={division.id}>
                              {division.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="startDate"
                        label="Ngày vào làm"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng chọn ngày vào làm!",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          format="DD/MM/YYYY" // Định dạng ngày
                          allowClear // Cho phép xóa giá trị
                          placeholder="Nhập ngày định dạng DD/MM/YYYY" // Placeholder
                          onChange={(date, dateString) => {
                            console.log("Selected Date:", dateString); // Xử lý nếu cần
                          }}
                        />
                      </Form.Item>
                      <Form.Item name="endDate" label="Ngày nghỉ việc">
                        <DatePicker
                          style={{ width: "100%" }}
                          format="DD/MM/YYYY" // Định dạng ngày
                          allowClear // Cho phép xóa giá trị
                          placeholder="Nhập ngày định dạng DD/MM/YYYY" // Placeholder
                          onChange={(date, dateString) => {
                            console.log("Selected Date:", dateString); // Xử lý nếu cần
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>

                {/* Tab Tài khoản ngân hàng */}
                <TabPane tab="Tài khoản ngân hàng" key="3">
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        name="bankName"
                        label="Tên ngân hàng"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng nhập tên ngân hàng!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="accountNumber"
                        label="Số tài khoản"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng nhập số tài khoản!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="accountHolder"
                        label="Tên tài khoản"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng nhập tên tài khoản!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Thông tin đăng nhập" key="4">
                  <Row gutter={16}>
                    {/* Tên đăng nhập */}
                    <Col span={8}>
                      <Form.Item
                        name="username"
                        label="Tên đăng nhập"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng nhập tên đăng nhập!",
                          },
                        ]}
                      >
                        <Input
                          disabled={isEditMode} // Không cho sửa khi ở chế độ edit
                          onBlur={() => generateEmployeeId()}
                        />
                      </Form.Item>
                    </Col>

                    {/* Mật khẩu */}
                    <Col span={8}>
                      <Form.Item
                        name="password"
                        label="Mật khẩu"
                        rules={
                          isEditMode
                            ? [] // Không cần nhập mật khẩu khi chỉnh sửa
                            : [
                                {
                                  required: true,
                                  message: "Vui lòng nhập mật khẩu!",
                                },
                              ]
                        }
                      >
                        {isEditMode ? ( // Nếu là chế độ edit thì chỉ hiển thị nút "Lấy lại mật khẩu"
                          <Button type="link">Lấy lại mật khẩu</Button>
                        ) : (
                          <Input.Password
                            addonAfter={
                              <Button type="link" onClick={generatePassword}>
                                Tạo mật khẩu
                              </Button>
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>

                    {/* Nhóm quyền */}
                    <Col span={8}>
                      <Form.Item
                        name="group"
                        label="Nhóm quyền"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng chọn nhóm quyền!",
                          },
                        ]}
                      >
                        <Select>
                          {groups.map((group) => (
                            <Option key={group.id} value={group.id}>
                              {group.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {isEditMode ? "Cập nhật" : "Thêm mới"}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default AdminEmployeesNew;
