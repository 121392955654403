import React, { useState, useEffect, useContext } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined, BellOutlined, UserOutlined, LogoutOutlined, UserOutlined as UserIcon } from '@ant-design/icons';
import { Layout, Input, Button, Avatar, Dropdown, Menu } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, Link } from 'react-router-dom';
import { AUTH_PATH, SIGN_IN_PATH } from 'constant/appPath';
import { UserContext } from "context/UserContext";
import classNames from "classnames/bind";
import styles from "page/Application/Application.module.scss";
const cx = classNames.bind(styles);

const { Header } = Layout;

const AppHeader = ({ collapsed, toggle }) => {
  const { logout } = useContext(UserContext);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userToken = localStorage.getItem('userToken');
    const userData = JSON.parse(localStorage.getItem('userData'));

    if (userToken && userData) {
      setIsLoggedIn(true);
      setUser(userData);
    }
  }, []);

  const urlLogin = `${AUTH_PATH}/${SIGN_IN_PATH}`;

  const handleLoginClick = () => {
    navigate(urlLogin); // Điều hướng đến trang login
  };

  const handleSearch = (value) => {
    if (value.trim()) {
      navigate(`/search?query=${value}`);
    }
  };

  const handleLogout = () => {
    logout();
    navigate(urlLogin);
  };

  const menu = (
    <Menu>
      {isLoggedIn ? (
        <>
          <Menu.Item key="name">
            <Avatar icon={<UserOutlined />} /> {user ? user.last_name + ' ' + user.first_name : "User"}
          </Menu.Item>
          <Menu.Item key="profile">
            <UserIcon /><Link to="account"> Hồ sơ cá nhân</Link>
          </Menu.Item>
          <Menu.Item key="logout">
            <LogoutOutlined /><span onClick={handleLogout}> Đăng xuất</span>
          </Menu.Item>
        </>
      ) : (
        <Menu.Item key="login" onClick={handleLoginClick}>
          <UserIcon /><span>Đăng nhập</span>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Header className={cx("site-layout-background")}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {isMobile && (
          <Button type="primary" onClick={toggle} style={{ marginLeft: '16px' }}>
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
        )}
        <Input.Search
          placeholder="Search"
          style={{ marginLeft: '16px', width: '200px' }}
          onSearch={handleSearch}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <div style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
        <BellOutlined />
        <Dropdown overlay={menu} placement="bottomRight">
          <Avatar icon={<UserOutlined />} style={{ cursor: 'pointer', marginLeft: '10px' }} />
        </Dropdown>
      </div>
    </Header>
  );
}

export default AppHeader;
