import React, { useState } from 'react';
import { Layout } from 'antd';
import { Outlet } from "react-router-dom";
import AppHeader from "layouts/components/AppHeader";
import classNames from "classnames/bind";
import styles from "page/Application/Application.module.scss";
import MenuLeft from "./MenuLeft";

const cx = classNames.bind(styles);

const { Content } = Layout;

function Application() {
    const [collapsed, setCollapsed] = useState(false);
    const [overlayVisible, setOverlayVisible] = useState(false);

    const toggle = () => {
        setCollapsed(!collapsed);
        setOverlayVisible(!overlayVisible); // Toggle overlay visibility
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            {collapsed && (
                <div className={cx("menu-left-overlay")} onClick={() => setCollapsed(false)} />
            )}
            <MenuLeft collapsed={collapsed} toggle={toggle} />
            <Layout className={cx("site-layout", { "content-overlay": overlayVisible })} style={{ marginLeft: collapsed ? 0 : 250 }}>
                <AppHeader collapsed={collapsed} toggle={toggle} />
                <Content>
                    <div className={cx("site-card-wrapper")}>
                        <Outlet />
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

export default Application;
