import React, { useState, useEffect } from "react";
import { Modal, Table, Button, message, Checkbox, Spin, Tabs } from "antd";
import {
  getListUserByGroupId,
  getListAllUser,
  updateGroupUsers,
  deleteGroupUsers,
} from "api/admin/permission.api";

const { TabPane } = Tabs;

const UserGroupModal = ({ visible, onCancel, groupId, groupName }) => {
  const [groupUsers, setGroupUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible && groupId) {
      fetchUsers();
    }
  }, [visible, groupId]);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const [allUsersResponse, groupUsersResponse] = await Promise.all([
        getListAllUser(groupId),
        getListUserByGroupId(groupId),
      ]);

      setGroupUsers(groupUsersResponse || []);
      setAllUsers(
        (allUsersResponse || []).filter(
          (user) =>
            !(groupUsersResponse || []).some(
              (groupUser) => groupUser.id === user.id
            )
        )
      );
    } catch (error) {
      message.error("Failed to fetch user data");
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveUser = async (userId) => {
    console.log("Check userID: ", userId);
    setLoading(true);
    try {
      const updatedGroupUsers = groupUsers.filter((user) => user.id !== userId);
      await deleteGroupUsers(groupId, userId);
      setGroupUsers(updatedGroupUsers);
      message.success("Xóa tài khoản khỏi nhóm quyền thành công");
    } catch (error) {
      message.error("Failed to remove user from group");
    } finally {
      setLoading(false);
    }
  };

  const handleAddUsers = async () => {
    setLoading(true);
    try {
      const selectedUsers = allUsers.filter((user) =>
        selectedUserIds.includes(user.id)
      );
      const updatedGroupUsers = [...groupUsers, ...selectedUsers];
      await updateGroupUsers(
        groupId,
        updatedGroupUsers.map((user) => user.id)
      );
      setGroupUsers(updatedGroupUsers);
      setAllUsers(
        allUsers.filter((user) => !selectedUserIds.includes(user.id))
      );
      setSelectedUserIds([]);
      message.success("Thêm tài khoản vào nhóm quyền thành công");
    } catch (error) {
      message.error("Failed to add users to group");
    } finally {
      setLoading(false);
    }
  };

  const columnsGroupUsers = [
    {
      title: "Tên đăng nhập",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Button type="link" onClick={() => handleRemoveUser(record.id)}>
          Xóa
        </Button>
      ),
    },
  ];

  const columnsAllUsers = [
    {
      title: "Tên đăng nhập",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Chọn",
      key: "select",
      render: (_, record) => (
        <Checkbox
          checked={selectedUserIds.includes(record.id)}
          onChange={(e) => {
            const checked = e.target.checked;
            setSelectedUserIds((prev) =>
              checked
                ? [...prev, record.id]
                : prev.filter((userId) => userId !== record.id)
            );
          }}
        />
      ),
    },
  ];

  return (
    <Modal
      title={`Quản lý tài khoản trong nhóm quyền ${groupName}`}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={800}
      maskClosable={false}
      keyboard={false}
    >
      {loading ? (
        <Spin
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        />
      ) : (
        <Tabs defaultActiveKey="1">
          <TabPane tab="Danh sách tài khoản" key="1">
            <Table
              rowKey="userId"
              columns={columnsGroupUsers}
              dataSource={groupUsers}
              pagination={{ pageSize: 10 }}
            />
          </TabPane>
          <TabPane tab="Thêm tài khoản" key="2">
            <Table
              rowKey="userId"
              columns={columnsAllUsers}
              dataSource={allUsers}
              pagination={{ pageSize: 10 }}
            />
            <Button
              type="primary"
              onClick={handleAddUsers}
              disabled={selectedUserIds.length === 0}
              style={{ marginTop: 16 }}
            >
              Thêm tài khoản vào nhóm quyền
            </Button>
          </TabPane>
        </Tabs>
      )}
    </Modal>
  );
};

export default UserGroupModal;
