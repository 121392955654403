import {
  HOMEPAGE,
  USER_PATH,
} from "./appPath";
import {
  HomeOutlined,
  UserOutlined,
} from "@ant-design/icons";

const menuAccount = [
  {
    key: "home",
    icon: <HomeOutlined />,
    label: "Trang chủ",
    path: `${HOMEPAGE}`,
  },
  {
    key: "profile",
    label: "Thông tin tài khoản",
    icon: <UserOutlined />,
    path: `${USER_PATH}`,
  },
];
export { menuAccount };
