import {
  ADMIN_PATH,
  ADMIN_USER_PATH,
  ADMIN_USER_NEW_PATH,
  ADMIN_SETTING_PATH,
  ADMIN_PROJECT_PATH,
  ADMIN_BUILDING_PATH,
  ADMIN_APARTMENT_PATH,
  ADMIN_POST_PATH,
  ADMIN_POST_NEW_PATH,
  ADMIN_POST_CATEGORY_PATH,
  ADMIN_M_STATUR_COLOR_PATH,
  ADMIN_SALES_POLICY_PATH,
  ADMIN_RENT_POST,
  ADMIN_EMPLOYEES_PATH,
  ADMIN_EMPLOYEES_NEW_PATH,
  ADMIN_USER_ROLE_PATH,
} from "./authPath";
import {
  PieChartOutlined,
  UserOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
  ProjectOutlined,
  HomeOutlined,
  ReadOutlined,
  BorderOutlined,
  DollarOutlined,
  ShoppingOutlined,
  ApartmentOutlined,
  TeamOutlined
} from "@ant-design/icons";

const menuItems = [
  {
    key: "dashboard",
    icon: <PieChartOutlined />,
    label: "Dashboard",
    path: `${ADMIN_PATH}`,
    permission: "dashboard",
  },
  {
    key: "project",
    label: "Dự án",
    icon: <ProjectOutlined />,
    permission: "project",
    children: [
      {
        key: "all_project",
        label: "Danh sách dự án",
        icon: <UnorderedListOutlined />,
        path: `${ADMIN_PATH}/${ADMIN_PROJECT_PATH}`,
        permission: "project",
      },
      {
        key: "all_building",
        label: "Danh sách tòa",
        icon: <UnorderedListOutlined />,
        path: `${ADMIN_PATH}/${ADMIN_BUILDING_PATH}`,
        permission: "project",
      },
      {
        key: "all_apartment",
        label: "Căn hộ",
        icon: <HomeOutlined />,
        path: `${ADMIN_PATH}/${ADMIN_APARTMENT_PATH}`,
        permission: "project",
      },
      {
        key: "all_rent_post",
        label: "Cho thuê",
        icon: <ShoppingOutlined />,
        path: `${ADMIN_PATH}/${ADMIN_RENT_POST}`,
        permission: "project",
      },
      {
        key: "all_sales_policy",
        label: "CS bán hàng",
        icon: <DollarOutlined />,
        path: `${ADMIN_PATH}/${ADMIN_SALES_POLICY_PATH}`,
        permission: "project",
      },
    ],
  },
  {
    key: "post",
    label: "Tin tức",
    icon: <ReadOutlined />,
    permission: "post",
    children: [
      {
        key: "all_post",
        label: "D/S tin tức",
        icon: <UnorderedListOutlined />,
        path: `${ADMIN_PATH}/${ADMIN_POST_PATH}`,
        permission: "post",
      },
      {
        key: "new_post",
        label: "Tạo mới tin tức",
        icon: <UnorderedListOutlined />,
        path: `${ADMIN_PATH}/${ADMIN_POST_NEW_PATH}`,
        permission: "post",
      },
    ],
  },
  {
    key: "all_category_post",
    label: "Danh mục",
    icon: <BorderOutlined />,
    path: `${ADMIN_PATH}/${ADMIN_POST_CATEGORY_PATH}`,
    permission: "all_category_post",
  },
  {
    key: "employees",
    label: "Quản lý nhân sự",
    icon: <UserOutlined />,
    permission: "employees",
    children: [
      {
        key: "all_employees",
        label: "DS nhân viên",
        icon: <TeamOutlined />,
        path: `${ADMIN_PATH}/${ADMIN_EMPLOYEES_PATH}`,
        permission: "employees",
      },
      {
        key: "add_new_employees",
        label: "Thêm nhân viên",
        icon: <UserAddOutlined />,
        path: `${ADMIN_PATH}/${ADMIN_EMPLOYEES_NEW_PATH}/`,
        permission: "employees",
      },
    ],
  },
  {
    key: "user_role",
    label: "Phân quyền",
    icon: <ApartmentOutlined />,
    path: `${ADMIN_PATH}/${ADMIN_USER_ROLE_PATH}/`,
    permission: "user_role",
  },
  // {
  //   key: "user_manager",
  //   label: "Quản lý phân quyền",
  //   icon: <UserOutlined />,
  //   children: [
  //     {
  //       key: "all_user",
  //       label: "Danh sách tài khoản",
  //       icon: <UnorderedListOutlined />,
  //       path: `${ADMIN_PATH}/${ADMIN_USER_PATH}`,
  //     },
  //     {
  //       key: "add_new_user",
  //       label: "Add new user",
  //       icon: <UserAddOutlined />,
  //       path: `${ADMIN_PATH}/${ADMIN_USER_NEW_PATH}/`,
  //     },
  //   ],
  // },
  {
    key: "setting_config",
    label: "Setup & Configurations",
    icon: <SettingOutlined />,
    permission: "setting_config",
    children: [
      {
        key: "general_configuration",
        label: "Cấu hình hệ thống",
        icon: <UnorderedListOutlined />,
        path: `${ADMIN_PATH}/${ADMIN_SETTING_PATH}`,
        permission: "setting_config",
      },
    ],
  },
  {
    key: "setting_config_data_master",
    label: "Data master",
    icon: <SettingOutlined />,
    permission: "setting_config_data_master",
    children: [
      {
        key: "general_m_status_color",
        label: "Trạng thái",
        icon: <UnorderedListOutlined />,
        path: `${ADMIN_PATH}/${ADMIN_M_STATUR_COLOR_PATH}`,
        permission: "setting_config_data_master",
      },
    ],
  },
];
export { menuItems };
