import React, { useEffect, useState } from "react";
import { Layout, Image, Menu, Avatar, Button, theme, Divider } from "antd";
import classNames from "classnames/bind";
import styles from "page/Application/Application.module.scss";
import logo from "assets/logo/Logo-MDLand-mau.png";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { menuAccount } from "constant/menuAccount";
import { UserOutlined } from "@ant-design/icons";
import { AUTH_PATH, SIGN_IN_PATH } from 'constant/appPath';

const cx = classNames.bind(styles);
const { Sider } = Layout;

const MenuLeft = ({ collapsed, toggle }) => {
  const [menuItems, setMenuItems] = useState(menuAccount);
  const [userInfo, setUserInfo] = useState({
    avatar: '',  // Default values
    name: 'Xin chào, Khách',
    status: '',
    actionLabel: 'Khám phá (Free)',  // Default label
    loggedIn: false,  // New field to track login state
  });
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
  let widthMenuLeft = 250;
  if (isTablet) {
    widthMenuLeft = 200;
  }

  const navigate = useNavigate();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    // Fetch user data from localStorage
    const userDataString = localStorage.getItem('userData');
    if (userDataString) {
      try {
        const userData = JSON.parse(userDataString); // Parse the stored user data
        setUserInfo({
          avatar: userData.avatar || '',   // Ensure a fallback value
          name: userData.last_name + ' ' + userData.first_name || 'Xin chào, Khách',  // Default to 'Guest' if no name
          status: userData.status || '',   // Fallback in case there's no status
          actionLabel: userData.actionLabel || 'Khám phá (Free)', // Default action label
          loggedIn: true,  // User is logged in
        });
      } catch (error) {
        console.error("Failed to parse userData from localStorage:", error);
      }
    }
  }, []);

  const onClick = (e) => {
    const item = findMenuItem(e.key, menuItems);
    if (item && item.path) {
      navigate(item.path);
    }
  };

  const findMenuItem = (key, items) => {
    for (const item of items) {
      if (item.key === key) {
        return item;
      }
      if (item.children) {
        const found = findMenuItem(key, item.children);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  const handleLogin = () => {
    const urlLogin = `${AUTH_PATH}/${SIGN_IN_PATH}`;
    navigate(urlLogin);  // Navigate to login page when login button is clicked
  };

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      breakpoint="md"
      collapsedWidth="0"
      width={widthMenuLeft}
      onBreakpoint={(broken) => {
        if (broken) {
          toggle(true);
        }
      }}
      style={{ position: 'fixed', left: 0, top: 0, bottom: 0, background: '#fff' }}
    >
      <div className={cx("logo")}>
        <Link to="/">
          <Image src={logo} preview={false} className={cx("logo-img")} />
        </Link>
      </div>
      {/* User Information Section */}
      <div className={cx("user-info")} style={{ textAlign: 'center', marginTop: '10px' }}>
        <Avatar
          src={userInfo.avatar || undefined} // Use undefined if no avatar to show a placeholder
          size={48}
          icon={!userInfo.avatar && <UserOutlined />} // Show placeholder if no avatar
          style={{ marginBottom: '20px' }}
        />
        <div>
          <strong>{userInfo.name}</strong>
        </div>
        <div style={{ color: '#ff4d4f', marginBottom: '5px' }}>
          {userInfo.status}
        </div>

        {/* Display Login button if not logged in */}
        {userInfo.loggedIn ? (
          <Button type="link" style={{ padding: 0, color: '#1890ff' }}>
            {userInfo.actionLabel}
          </Button>
        ) : (
          <Button type="primary" onClick={handleLogin}>
            Đăng nhập
          </Button>
        )}
      </div>
      <Divider />
      <Menu
        defaultSelectedKeys={['profile']}
        defaultOpenKeys={['new']}
        mode="inline"
        theme="light"
        inlineCollapsed={collapsed}
        items={menuItems}
        onClick={onClick}
      />
    </Sider>
  );
};

export default MenuLeft;
