import React, { useState, useEffect } from "react";
import {
  Button,
  Layout,
  Row,
  Col,
  Table,
  Pagination,
  Modal,
  message,
} from "antd";
import { Link } from "react-router-dom";
import { getListAllUser, deleteUser } from "api/admin/user.api";

const { Content } = Layout;

const AdminUser = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getListAllUser(perPage, page);
      setUsers(response);
    };

    fetchData();
  }, [perPage, page]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const getVisibleUsers = () => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    return users.slice(startIndex, endIndex);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteUser(itemToDelete);
      message.success("Thực hiện thành công");
      setShowDeleteConfirm(false);
      const updatedList = users.filter((item) => item.id !== itemToDelete);
      setUsers(updatedList);
    } catch (error) {
      message.error("Thực hiện xóa thất bại và có lỗi: " + error.message);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteConfirm(false);
    setItemToDelete(null);
  };

  const handleDelete = (id) => {
    setItemToDelete(id);
    setShowDeleteConfirm(true);
  };

  const columns = [
    { title: "#", dataIndex: "id", key: "id" },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => `${record.last_name} ${record.first_name}`,
    },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Phone number", dataIndex: "phone_number", key: "phone_number" },
    { title: "Address", dataIndex: "address", key: "address" },
    { title: "Role", dataIndex: "role", key: "role" },
    {
      title: "Option",
      key: "option",
      render: (text, record) => (
        <>
          <Button type="primary" danger onClick={() => handleDelete(record.id)}>
            Delete
          </Button>
          <Button
            type="primary"
            style={{ marginLeft: 8 }}
            as={Link}
            to={`/admin/user/edit/${record.id}`}
          >
            Edit
          </Button>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <Content style={{ margin: "15px" }}>
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: "16px" }}
        >
          <Col>
            <h2>Users</h2>
          </Col>
          {/* <Col>
            <Button type="primary" as={Link} to="/admin/user/new">
              Add new user
            </Button>
          </Col> */}
        </Row>
        <Table
          dataSource={getVisibleUsers()}
          columns={columns}
          rowKey="id"
          pagination={false}
        />
        <Pagination
          current={page}
          pageSize={perPage}
          total={users.length}
          onChange={handlePageChange}
          style={{ marginTop: "16px", textAlign: "center" }}
        />
        <Modal
          title="Xác nhận xóa"
          visible={showDeleteConfirm}
          onOk={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        >
          <p>Bạn có chắc chắn muốn xóa?</p>
        </Modal>
      </Content>
    </Layout>
  );
};

export default AdminUser;
