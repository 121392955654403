import axios from "axios";
import { SIGN_IN_PATH, AUTH_PATH } from "constant/appPath";

const TIME_OUT = 20000;

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

const axiosClient = axios.create({
  baseURL: apiUrl,
  timeout: TIME_OUT,
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to handle configuring headers for file uploads
const configureFileUploadHeaders = (config) => {
  config.headers = {
    "Content-Type": "multipart/form-data",
  };
  return config;
};

// Request interceptor - Check for file uploads and set headers accordingly
axiosClient.interceptors.request.use(
  (config) => {
    const hasFile = config.data instanceof FormData; // Check if data is FormData (indicating file upload)
    if (hasFile) {
      configureFileUploadHeaders(config);
    }

    let authenticationData = localStorage.getItem("userToken");

    if (authenticationData) {
      config.headers["authorization"] = "Bearer " + authenticationData;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor remains the same
axiosClient.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log("check lỗi hệ thống user: ", error.response.status);
    if (error.response) {
      const { status, data } = error.response;

      // Xử lý khi lỗi là 401 hoặc 403 (xác thực thất bại)
      if (status === 401 || status === 403) {
        if (data.status_code === "LOGIN_NG") {
          return Promise.reject(data); // Trả về thông báo lỗi từ server
        } else {
          localStorage.removeItem("user_token");
          window.location.href = AUTH_PATH + "/" + SIGN_IN_PATH; // Chuyển hướng đến trang đăng nhập
        }
      }

      // Xử lý lỗi 400 - Bad Request
      if (status === 400) {
        return Promise.reject({
          message: "Yêu cầu không hợp lệ",
          status,
          data,
        });
      }

      // Xử lý lỗi khác
      return Promise.reject({
        message: "Lỗi hệ thống, vui lòng thử lại sau.",
        status,
        data,
      });
    } else {
      return Promise.reject({
        message: error.message || "Lỗi không xác định",
      });
    }
  }
);

export default axiosClient;
