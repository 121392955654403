import React, { useContext, useState } from "react";
import { Form, Input, Button, message, Row, Col, Spin } from "antd";
import { AdminContext } from "context/AdminContext";
import { useNavigate, Navigate } from "react-router-dom";
import { login as adminLogin } from "api/auth/authenAdmin.api";
import { ADMIN_PATH, ADMIN_PATH_LOGIN } from "constant/authPath";

const AdminLogin = () => {
  const { login } = useContext(AdminContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // State to control spin visibility

  const onFinish = async (values) => {
    setIsLoading(true); // Show spin indicator before API call
    try {
      const response = await adminLogin(values.email, values.password);
      if (response.status_code === "LOGIN_OK") {
        if (response.user.role === "admin") {
          login(response.user, response.access_token);
          message.success("Đăng nhập thành công!");
          navigate(ADMIN_PATH);
        } else {
          message.error("Tài khoản của bạn không có quyền admin!");
          navigate(ADMIN_PATH_LOGIN);
        }
      } else {
        console.log("Đăng nhập thất bại: ", response.message);
        message.info("Đăng nhập thất bại: " + response.message);
      }
    } catch (error) {
      console.log("Check error login admin: ", error);
    } finally {
      setIsLoading(false); // Hide spin indicator after API call completes
    }
  };

  if (localStorage.getItem("adminToken")) {
    return <Navigate to={ADMIN_PATH} />;
  }

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>MDLand đăng nhập</h1>
      <Form
        name="admin_login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        style={{ maxWidth: 500, margin: "0 auto" }}
      >
        <Row gutter={16}>
          <Col span={8}>Tên đăng nhập</Col>
          <Col span={16}>
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>Mật khẩu</Col>
          <Col span={16}>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          {/* Wrap the button with Spin component */}
          <Spin spinning={isLoading}>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              Đăng nhập
            </Button>
          </Spin>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AdminLogin;
