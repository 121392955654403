import React from "react";
import { Outlet } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "page/AuthAdmin/AuthAdmin.module.scss";
import { Row, Col } from "antd";
import { Helmet } from 'react-helmet';

const cx = classNames.bind(styles);

const AuthAdmin = () => {
  return (
    <div className={cx("auth-main-wrapper")}>
      <Helmet>
        <title>MDLand - Login</title>
      </Helmet>
      <Row>
        <Col span={9}></Col>
        <Col span={6} className={cx("auth-content")}>
          <Outlet />
        </Col>
        <Col span={9}></Col>
      </Row>
    </div>
  );
};

export default AuthAdmin;
