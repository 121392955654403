// Thiết định Trạng thái nhân sự
export const STATUS = [
  {
    value: "1",
    label: "Thử việc",
  },
  {
    value: "2",
    label: "Chính thức",
  },
  {
    value: "3",
    label: "Đã nghỉ việc",
  },
];
